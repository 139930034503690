import { BaseComponent } from '@abstract/BaseComponent';
import { Component, OnInit } from '@angular/core';
import Utils from '@app/admin/components/map/utils';
import { PaginationData } from '@app/model/PaginationData';
import { Const } from '@const/Const';
import { BizUtil } from '@services/biz';
import { PlanningService } from '@services/planning.service';
import { Const as GlobalConst } from "@wearewarp/universal-libs"
import dayjs from 'dayjs';
import _ from 'underscore';
import { ActivatedRoute } from '@angular/router';

interface TabData {
  key: string, name: string, apiPath?: string, data: any, actions?: any[]
}

@Component({
  selector: 'shipment-queue-v2',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class ShipmentQueueV2 extends BaseComponent {
  // planningService: PlanningService
  readonly tabs: TabData[] = [
    {
      key: 'needAppointment',
      name: 'Need Appointment',
      apiPath: 'need_appt',
      data: new PaginationData(),
    },
    // {
    //   key: 'verificationNeeded',
    //   name: 'Verification Needed',
    //   data: [],
    // },
    // {
    //   key: 'pending',
    //   name: 'Pending',
    //   data: [],
    // },
    // {
    //   key: 'planned',
    //   name: 'Planned',
    //   data: [],
    // }
  ];

  tabIndex = 0;
  get currentTab(): TabData {
    return this.tabs[this.tabIndex];
  }
  constructor(protected activatedRoute: ActivatedRoute) {
    super();
    // this.activatedRoute.queryParams.subscribe((r) => this.deserializeQueryParams(r))
    // this.planningService = new PlanningService(this.api)
  }

  ngOnInit(): void {
    // this.loadWarehouses()
    // this.loadShipments();
    this.getSummary();
    this.autoShrinkSideBar();
  }

  private sumData: any = {};
  getTabLabel(tab: TabData): string {
    let key = tab.key;
    let count = this.sumData?.[key] ?? 0;
    return `${tab.name} (${count})`;
  }

  onSumDataUpdated(tab: TabData, data: { total: number }) {
    if (!this.sumData) {
      return;
    }
    this.sumData[tab.key] = data.total;
  }

  private tabIndexToKey(index: number): string {
    if (index >= 0 && index < this.tabs.length) {
      return this.tabs[index].key;
    }
    return this.tabs[0].key;
  }

  onTabChange(event) {
    if(event.index === 0 || (this.tabIndex === 0 && event.index !== 0)) {
      this.routeWithQueryUrl({ tab: this.tabIndexToKey(event.index) }, true);
      return;
    }
    this.routeWithQueryUrl({ tab: this.tabIndexToKey(event.index) }, false);
  }

  private getSummary() {
    let url = Const.APIURI_SHIPMENT_QUEUE('summary');
    this.api.GET(url).subscribe(
      resp => {
        this.sumData['needAppointment'] = resp.data?.needAppointment ?? 0;
      }, err => {
        this.showErr(err);
      }
    );
  }
  // loading: boolean = false
  // ascending: boolean = true
  // readinessLevels = [
  //   { value: 0, label: 'All' },
  //   { value: 2, label: 'Inbound Scheduled' },
  //   { value: 3, label: 'Inbound In-progress' },
  //   { value: 4, label: 'Dropped Off at Crossdock' },
  //   { value: 5, label: 'Confirmed' },
  // ]
  // _filter: any = {
  //   maxMileage: 0,
  //   requiresTime: false,
  //   clients: [],
  //   markets: [],
  //   readinessLevel: 0,
  //   validated: '',
  //   dateRange: '=',
  //   transitTypes: new Set(),
  // }
  // _shipments: any[] = []
  // _clientMap: any = {}
  // dates: any[] = []
  // markets: string[] = []
  // filtered = []
  // locations = []
  // setDateRange(range) {
  //   this._filter.dateRange = range
  //   this.onFilterChange(range)
  // }
  // get shipments() {
  //   return this._shipments
  // }
  // set shipments(v) {
  //   // old data
  //   const excluded: Set<string> = new Set()
  //   if (this._shipments) {
  //     for (let s of this._shipments) {
  //       if (s.excluded) {
  //         excluded.add(s.id)
  //       }
  //     }
  //   }
  //   let shipments = v.filter(it => {
  //     if (!it.metadata?.parent?.legIds) return true
  //     // I'm not child of my parent
  //     if (it.metadata.parent.legIds.indexOf(it.id) < 0) {
  //       console.log(`Orphan Child: ${it.id} ${it.warpId}`)
  //       return false
  //     }
  //     return true
  //   })
  //   if (this.ascending) {
  //     shipments = _.sortBy(shipments, 'warpId')
  //   } else {
  //     shipments = _.sortBy(shipments, it => -it.warpId)
  //   }
  //   for (let s of shipments) {
  //     if (excluded.has(s.id)) {
  //       s.excluded = true
  //     }
  //   }
  //   this._shipments = this.preprocessShipments(v)
  //   this.processShipments()
  // }

  // onTransitTypeChange(type) {
  //   if (type === 'all') {
  //     this._filter.transitTypes = new Set()
  //   } else {
  //     if (this._filter.transitTypes?.has(type)) {
  //       this._filter.transitTypes.delete(type)
  //     } else {
  //       this._filter.transitTypes = new Set([type])
  //     }
  //   }
  //   this.onFilterChange(type)
  // }

  // transitTypeOptions = [
  //   { value: 'first', label: 'first' },
  //   { value: 'last', label: 'last' },
  //   { value: 'middle', label: 'middle' },
  //   { value: 'none', label: 'main' },
  // ]

  // onFilterChange(event) {
  //   this.router.navigate(
  //     [],
  //     {
  //       relativeTo: this.activatedRoute,
  //       queryParams: this.serializeQueryParams(),
  //       queryParamsHandling: ''
  //     }
  //   );
  // }

  // serializeQueryParams() {
  //   const params: any = {}
  //   if(this._filter.keyword) {
  //     params['keyword'] = this._filter.keyword
  //   }
  //   if (this._filter.date) {
  //     const prefix = this._filter.dateRange === '<' || this._filter.dateRange === '>' ? this._filter.dateRange : ''
  //     params['date'] = prefix + this._filter.date
  //   }
  //   if (this._filter.transitTypes?.size) {
  //     params['transitTypes'] = [...this._filter.transitTypes].join(',')
  //   }
  //   if (this._filter.markets?.length) {
  //     params['markets'] = this._filter.markets.join(',')
  //   }
  //   if (this._filter.clients?.length) {
  //     params['clients'] = this._filter.clients.join(',')
  //   }
  //   if (this._filter.excludeClients?.length) {
  //     params['excludeClients'] = this._filter.excludeClients.join(',')
  //   }
  //   if (this._filter.xdock) {
  //     params['xdock'] = this._filter.xdock
  //   }
  //   if (this._filter.maxMileage) {
  //     params['mileage'] = this._filter.maxMileage
  //   }
  //   if (this._filter.pickupReady) {
  //     // params['ready'] = 'true'
  //   }
  //   if (this._filter.readinessLevel) {
  //     params['ready'] = this._filter.readinessLevel
  //   }
  //   if (this._filter.validated) {
  //     params['validated'] = this._filter.validated
  //   }
  //   return {
  //     tab: this.tabIndexToKey(this.tabIndex),
  //     filter: JSON.stringify(params)
  //   }
  // }
  // deserializeQueryParams(params) {
  //   const { tab } = params;
  //   this.tabIndex = this.tabs.findIndex(it => it.key === tab);
  //   if(!this.tabIndex) {
  //     this.tabIndex = 0;
  //   }
  //   const filter = params?.filter || '{}';
  //   const { date, markets, clients, mileage, ready, excludeClients, validated, xdock, transitTypes, keyword } = JSON.parse(filter) || {};
  //   if (date) {
  //     const prefix = date.substring(0, 1)
  //     if (prefix === '<' || prefix === '>') {
  //       this._filter.dateRange = prefix
  //       this._filter.date = date.substring(1)
  //     } else
  //       this._filter.date = date
  //   } else {
  //     delete this._filter?.date
  //   }
  //   if (markets) {
  //     this._filter.markets = markets.split(',')
  //   } else {
  //     this._filter.markets = []
  //   }
  //   if (clients) {
  //     this._filter.clients = clients.split(',')
  //   } else {
  //     this._filter.clients = []
  //   }
  //   if (excludeClients) {
  //     this._filter.excludeClients = excludeClients.split(',')
  //   } else {
  //     this._filter.excludeClients = []
  //   }
  //   if (mileage) {
  //     this._filter.maxMileage = parseInt(mileage)
  //   } else {
  //     this._filter.maxMileage = 0
  //   }
  //   if (ready) {
  //     // this._filter.pickupReady = ready === 'true'
  //     this._filter.readinessLevel = parseInt(ready)
  //   } else {
  //     delete this._filter?.readinessLevel
  //   }
  //   if (validated) {
  //     this._filter.validated = validated
  //   } else {
  //     this._filter.validated = ''
  //   }
  //   if (xdock) {
  //     this._filter.xdock = xdock
  //   } else {
  //     delete this._filter?.xdock
  //   }
  //   if (transitTypes) {
  //     this._filter.transitTypes = new Set(transitTypes.split(','))
  //   } else {
  //     this._filter.transitTypes = new Set()
  //   }
  //   if(keyword) {
  //     this._filter.keyword = keyword
  //   } else {
  //     delete this._filter?.keyword
  //   }
  //   this.processShipments()
  // }

  // isMissingTime(info): boolean {
  //   if (info.skipAppointment) return false
  //   if (info.requiresAppointment) {
  //     if (!info.appointmentInfo?.from) {
  //       return true
  //     }
  //   } else {
  //     const t = (info.windows || [])[0]
  //     if (!t?.from) return true
  //   }
  //   return false
  // }

  // processShipmentFilter(shipment, filter): boolean {
  //   if (!shipment) return false
  //   if (!filter) return true
  //   const { metadata } = shipment
  //   const { traffic, pickupDate, dropoffDate } = metadata || {}
  //   const { distance, time } = traffic || {}
  //   const { maxMileage, requiresTime, date, markets, excludeLocations, clients, excludeClients, pickupReady, keyword, readinessLevel, validated, dateRange, xdock, transitTypes } = filter || {}
  //   if (keyword) {
  //     const { warpId, order } = shipment
  //     if (warpId?.toString() !== keyword && order?.warpId?.toString() !== keyword) return false
  //   }
  //   if (maxMileage) {
  //     if (distance && distance > (maxMileage * 1609.34)) return false
  //   }
  //   if (requiresTime) {
  //     const infos = shipment.deliveryInfos.filter(it => it.type === 'PICKUP' || it.type === 'DROPOFF')
  //     let missing = true
  //     for (let info of infos) {
  //       if (!this.isMissingTime(info)) {
  //         missing = false
  //       }
  //     }
  //     if (missing) return false
  //   }
  //   if (pickupReady) {
  //     if (!shipment.metadata?.readiness?.level) return false
  //   }
  //   if (readinessLevel) {
  //     const level = shipment.metadata?.readiness?.level || 0
  //     if (level < readinessLevel) return false
  //   }
  //   if (validated == 'error') {
  //     if (!shipment.metadata?.issue) return false
  //   }
  //   if (validated == 'no-error') {
  //     if (shipment.metadata?.issue) return false
  //   }
  //   if (date) {
  //     if (date === 'N/A') {
  //       if (pickupDate !== date || dropoffDate !== date) {
  //         return false
  //       }
  //     } else {
  //       if (pickupDate === 'N/A' && dropoffDate === 'N/A') return false
  //       if (dateRange === '<') {
  //         if (pickupDate !== 'N/A' && pickupDate >= date) {
  //           return false
  //         }
  //         if (dropoffDate !== 'N/A' && dropoffDate >= date) {
  //           return false
  //         }
  //       } else if (dateRange === '>') {
  //         if (pickupDate !== 'N/A' && pickupDate <= date) {
  //           return false
  //         }
  //         if (dropoffDate !== 'N/A' && dropoffDate <= date) {
  //           return false
  //         }
  //       } else {
  //         if (pickupDate !== date && dropoffDate !== date) {
  //           return false
  //         }
  //       }
  //     }
  //   }
  //   if (clients?.length) {
  //     if (clients.indexOf(shipment.clientId) < 0) return false
  //   }
  //   if (excludeClients?.length) {
  //     if (excludeClients.indexOf(shipment.clientId) >= 0) return false
  //   }
  //   const pickup = BizUtil.getPickInfo(shipment)
  //   const dropoff = BizUtil.getDropInfo(shipment)
  //   if (markets?.length) {
  //     if ((!pickup.market || markets.indexOf(pickup.market) < 0)
  //       && (!dropoff.market || markets.indexOf(dropoff.market) < 0))
  //       return false
  //   }
  //   if (excludeLocations?.length) {
  //     if (pickup.warehouseId && excludeLocations.indexOf(pickup.warehouseId as string) >= 0) {
  //       return false
  //     }
  //     if (dropoff.warehouseId && excludeLocations.indexOf(dropoff.warehouseId as string) >= 0) {
  //       return false
  //     }
  //   }
  //   if (xdock) {
  //     if (pickup.warehouseId !== xdock && dropoff.warehouseId !== xdock) {
  //       return false
  //     }
  //   }
  //   if (transitTypes?.size) {
  //     const { metadata } = shipment
  //     const leg = metadata?.mile ?? 'none'
  //     if (leg && !transitTypes.has(leg))
  //       return false
  //   }

  //   return true
  // }



  // _warehouses: any[] = []
  // xdocks: any[] = []
  // get warehouses() {
  //   return this._warehouses
  // }
  // set warehouses(v) {
  //   this._warehouses = v
  //   this.processWarehouses()
  //   // this.map?.refresh()
  // }

  // //filter
  // processWarehouses() {
  //   const locationIds: Set<string> = new Set()
  //   for (let loc of this.locations || []) {
  //     locationIds.add(loc.id)
  //   }
  //   for (let warehouse of this._warehouses) {
  //     if (locationIds.has(warehouse.id)) {
  //       warehouse.display = true
  //     } else {
  //       warehouse.display = false
  //     }
  //   }
  //   // this.map?.loadWarehouses(this._warehouses.filter(it => it.display))
  //   this.xdocks = this._warehouses.filter(it => it.display).filter(it => it.warehouseType === 'crossdock')
  // }

  // //filter
  // loadWarehouses() {
  //   const url = `${Const.APIURI_WAREHOUSES}?limit=-1&filter=${JSON.stringify({ warehouseType: 'crossdock' })}`
  //   this.api.GET(url).subscribe((res) => {
  //     this.warehouses = res.data.list_data
  //   })
  // }

  // validateWindow(window) {
  //   if (!window) return null
  //   if (!window.from && !window.to) return null
  //   const latest = window.to || window.from
  //   if (Date.parse(latest) < Date.now()) {
  //     return 'Time Window is too old!'
  //   }
  //   if (window.from === window.to) {
  //     return 'Time Window is too small. Minimum Time Window is 30 minutes'
  //   }
  //   if (window.from && window.to) {
  //     if (Date.parse(window.to) - Date.parse(window.from) <= 29 * 60 * 1000) {
  //       // console.log(window.to, window.from)
  //       return 'Time Window is too small. Minimum Time Window is 30 minutes'
  //     }
  //   }
  // }

  // isReady(shipment): { message: string, level: number } {
  //   const { metadata } = shipment || {}
  //   const { mile, relatedLegs } = metadata || {}

  //   if (shipment.review?.status?.PICKUP_READY == 'NEED_REVIEW') return { message: 'Pickup Info Needs Review', level: 0 }
  //   if (shipment.review?.status?.PICKUP_READY == 'CONFIRMED') return { message: mile === 'first' ? 'Pickup Ready Confirmed' : 'Ready for Outbound', level: 5 }

  //   if (mile !== 'last') return null

  //   const pickupWarehouseId = BizUtil.getPickInfo(shipment).warehouseId
  //   if (!pickupWarehouseId) return null

  //   const inboundLegs = (relatedLegs || []).filter(it => BizUtil.getDropInfo(it).warehouseId === pickupWarehouseId)
  //   if (!inboundLegs?.length) return null

  //   if (inboundLegs.filter(it => it.status === GlobalConst.ShipmentStatus.complete).length)
  //     return { message: 'Dropped off at Warehouse', level: 4 }

  //   if (inboundLegs.filter(it => it.status === GlobalConst.ShipmentStatus.inRouteToDropoff || it.status === GlobalConst.ShipmentStatus.pickupSuccessful).length)
  //     return { message: 'Inbound In Progress', level: 3 }

  //   if (inboundLegs.filter(it => it.status === GlobalConst.ShipmentStatus.booked || it.status === GlobalConst.ShipmentStatus.needCarrier || it.status === GlobalConst.ShipmentStatus.dispatched).length)
  //     return { message: 'Inbound Scheduled', level: 2 }

  //   return null
  // }

  // preprocessShipments(shipments) {
  //   // process clients
  //   for (let shipment of shipments) {
  //     const client = shipment.client ?? shipment.metadata?.client
  //     if (client) {
  //       this._clientMap[client.id] = client
  //     }
  //   }
  //   for (let shipment of shipments) {
  //     const pickup = BizUtil.getPickInfo(shipment)
  //     const dropoff = BizUtil.getDropInfo(shipment)
  //     let issue = null
  //     if (shipment.status !== 'complete' && !shipment.lastJobId) {
  //       const pickupWindow = BizUtil.getTimeWindowForDeliveryInfo(pickup)
  //       const dropoffWindow = BizUtil.getTimeWindowForDeliveryInfo(dropoff)
  //       if (pickup.requiresAppointment && !pickup.appointmentInfo?.from) {
  //         issue = 'Pickup requires Appointment'
  //       }
  //       if (!issue) {
  //         issue = this.validateWindow(pickupWindow.window)
  //         if (issue) {
  //           issue = 'Pickup ' + issue
  //         }
  //       }
  //       if (!issue) {
  //         if (dropoff.requiresAppointment && !dropoff.appointmentInfo?.from) {
  //           issue = 'Dropoff requires Appointment'
  //         }
  //       }
  //       if (!issue) {
  //         issue = this.validateWindow(dropoffWindow.window)
  //         if (issue) {
  //           issue = 'Delivery ' + issue
  //         }
  //       }
  //       if (!issue) {
  //         if (dropoffWindow.window?.from && pickupWindow.window?.from) {
  //           if (Date.parse(dropoffWindow.window?.from) - Date.parse(pickupWindow.window?.from) > 18 * 3600 * 1000) {
  //             console.log('Issue', Date.parse(dropoffWindow.window?.from), Date.parse(pickupWindow.window?.from))
  //             issue = 'Delivery Time and Pickup Time are too much different.'
  //           }
  //         }
  //       }
  //     }
  //     const { clientId, client } = shipment
  //     if (!client) {
  //       const c = this._clientMap[clientId]
  //       if (c) {
  //         shipment.client = c
  //       }
  //     }

  //     shipment.metadata = Object.assign(shipment.metadata ?? {}, {
  //       pickupDate: Utils.getDisplayDate(pickup),
  //       dropoffDate: Utils.getDisplayDate(dropoff),
  //       readiness: this.isReady(shipment),
  //       issue: issue
  //     })
  //   }
  //   return shipments.filter(it => it.review?.needReview !== true)
  // }

  // processShipments() {
  //   this.filterShipments()
  //   this.processLocations()
  //   this.processWarehouses()

  //   // this.map?.loadShipments(this.filtered)

  //   // this.map?.refresh()
  //   // this.fitMapBound()
  // }

  // //filter
  // processLocations() {
  //   const locationMap = {}
  //   for (let shipment of this._shipments) {
  //     for (let info of shipment.deliveryInfos) {
  //       if (info.warehouseId && info.locationName) {
  //         const loc = { id: info.warehouseId, name: info.locationName }
  //         if (!locationMap[loc.id]) {
  //           locationMap[loc.id] = loc
  //         }
  //       }
  //     }
  //   }
  //   this.locations = Object.values(locationMap)
  // }

  // // fitMapBound() {
  // //   this.map?.fitBoundsToShipment()
  // // }

  // //filter
  // filterShipments() {
  //   this.calculateDays()
  //   this.calculateDateFacet()
  //   this.calculateMarketFacet()
  //   this.calculateClientFacet()

  //   // this._shipments = this._shipments.filter(it => it.review?.needReview !== true)

  //   this.filtered = this._shipments.filter(it => this.processShipmentFilter(it, this._filter));
  //   this.filtered = this.filtered.map(it => this.processShipment(it))
  //   this.splitShipmentsToTab(this.filtered)
  // }

  // processShipment(shipment) {
  //   const pickup = BizUtil.getPickInfo(shipment)
  //   const dropoff = BizUtil.getDropInfo(shipment)

  //   const { metadata } = shipment || {}
  //   const { mile, issue } = metadata || {}
  //   const transitType = shipment.shipmentTransitType == 'none' ? 'main' : shipment.shipmentTransitType

  //   return {
  //     id: shipment.id,
  //     warpId: shipment.warpId,
  //     code: shipment.code,
  //     excluded: shipment.excluded,
  //     clientName: shipment.client?.name || shipment.metadata?.client?.name,
  //     pickup,
  //     dropoff,
  //     pickupWindow: pickup ? BizUtil.getDeliveryInfoTime(pickup, { showWindow: true, format: 'M/D h:mm A', formatDateOnly: 'M/D' }) || '-' : '-',
  //     dropoffWindow: dropoff ? BizUtil.getDeliveryInfoTime(dropoff, { showWindow: true, format: 'M/D h:mm A', formatDateOnly: 'M/D' }) || '-' : '-',
  //     issue,
  //     orderId: shipment.order?.id ?? shipment.metadata?.order?.id,
  //     parentId: shipment.metadata?.parent?.id,
  //     orderWarpId: shipment.order?.warpId ?? shipment.metadata?.order?.warpId,
  //     reviewStatus: shipment.review?.status,
  //     transitType: transitType ? ((mile ? mile + ' ' : '') + transitType) : 'main',
  //     readiness: shipment.metadata.readiness,
  //     session: shipment.metadata.session,
  //     metadata: shipment.metadata,
  //     onHold: shipment.tags?.includes('HOLD') || shipment.metadata?.order?.tags?.includes('HOLD')
  //   }
  // }
  // planned: any[] = []
  // pending: any[] = []
  // verificationNeeded: any[] = []
  // splitShipmentsToTab(shipments) {
  //   const t0 = Date.now()
  //   this.planned = shipments.filter(it => it.session?.id)
  //   const apptRequired = (it) => (it.dropoff.requiresAppointment && !it.dropoff.appointmentInfo?.from) || (it.pickup.requiresAppointment && !it.pickup.appointmentInfo?.from)
  //   const unplanned = shipments.filter(it => !it.session?.id).filter(it => !apptRequired(it))
  //   const isPending = (shipment) => {
  //     const { pickupDate, dropoffDate } = shipment?.metadata || {}
  //     return  !['main', 'first leg','last leg'].includes(shipment.transitType) && (pickupDate == 'N/A' && dropoffDate == 'N/A')
  //   }
  //   this.verificationNeeded = _.sortBy(unplanned.filter(it => !isPending(it)), it => it.warpId).reverse()
  //   this.pending = _.sortBy(unplanned.filter(isPending), it => it.warpId).reverse()
  //   this.sumData['verificationNeeded'] = this.verificationNeeded.length;
  //   this.sumData['pending'] = this.pending.length;
  //   this.sumData['planned'] = this.planned.length;
  //   const t1 = Date.now()
  //   console.log(`${t1} ShipmentList::filterShipments: Done processing shipments after ${t1 - t0}ms`)
  // }

  // //filter
  // today: string = ''
  // tomorrow: string = ''
  // nextWorkingDay: string = ''
  // coming: string[] = []


  // calculateDays() {
  //   const now = dayjs()
  //   const today = now.startOf('day')
  //   const tomorrow = today.add(1, 'day')
  //   const dow = tomorrow.day()
  //   const nextWorkingDay = dow == 0 ? tomorrow.add(1, 'day') : dow == 6 ? tomorrow.add(2, 'day') : tomorrow
  //   const coming: any[] = []
  //   let day = today
  //   coming.push(day)
  //   while (day.isBefore(nextWorkingDay)) {
  //     day = day.add(1, 'day')
  //     coming.push(day)
  //   }
  //   this.today = today.format('YYYY-MM-DD')
  //   this.tomorrow = tomorrow.format('YYYY-MM-DD')
  //   this.nextWorkingDay = nextWorkingDay.format('YYYY-MM-DD')
  //   this.coming = coming.map(it => it.format('YYYY-MM-DD'))
  // }
  // calculateDateFacet() {
  //   // const filtered = this._shipments.filter(it => this.processShipmentFilter(it, Object.assign({}, this._filter, {date: null})))
  //   const dates: any[] = []
  //   for (let shipment of this._shipments) {
  //     if (shipment.metadata.pickupDate == 'N/A') {
  //       dates.push({
  //         date: 'N/A',
  //         display: 'N/A'
  //       })
  //     }
  //     else if (shipment.metadata.pickupDate) {
  //       const d = dayjs(shipment.metadata.pickupDate)
  //       if (d) {
  //         // console.log(shipment.metadata.pickupDate)
  //       }
  //       dates.push({
  //         date: shipment.metadata.pickupDate,
  //         display: d.format('MMM DD')
  //       })
  //     }
  //     // dates.push(shipment.metadata.dropoffDate)
  //   }

  //   this.dates = _.sortBy(_.uniq(dates, it => it.date), it => it.date).reverse()
  // }
  // //filter
  // calculateMarketFacet() {
  //   const filtered = this._shipments.filter(it => this.processShipmentFilter(it, Object.assign({}, this._filter, { markets: null })))
  //   const markets: string[] = []
  //   for (let shipment of filtered) {
  //     const pickup = BizUtil.getPickInfo(shipment)
  //     const dropoff = BizUtil.getDropInfo(shipment)
  //     if (pickup.market) {
  //       markets.push(pickup.market)
  //     }
  //     if (dropoff.market) {
  //       markets.push(dropoff.market)
  //     }
  //   }
  //   this.markets = _.uniq(markets).sort()
  // }
  // //filter
  // clients = []
  // calculateClientFacet() {
  //   // const filtered = this._shipments.filter(it => this.processShipmentFilter(it, Object.assign({}, this._filter, {clients: null, excludeClients: null})))
  //   const clients: any[] = []
  //   const clientIds: Set<string> = new Set()
  //   for (let shipment of this._shipments) {
  //     const client = shipment.client ?? shipment.metadata?.client
  //     if (client && !clientIds.has(shipment.clientId)) {
  //       clients.push(client)
  //       clientIds.add(client.id)
  //     }
  //   }

  //   this.clients = clients
  // }

  // processPlanningSessions(shipments, sessions) {
  //   const byShipment = {}
  //   for (let session of sessions) {
  //     const l = byShipment[session.shipmentId] || []
  //     if (session.metadata?.session) {
  //       l.push(session.metadata?.session)
  //       byShipment[session.shipmentId] = l
  //     }
  //   }
  //   for (let shipment of shipments) {
  //     const mySessions = byShipment[shipment.id] || []
  //     const { metadata } = shipment
  //     const { pickupDate, dropoffDate } = metadata || {}
  //     const valid = mySessions.filter(it => {
  //       const d = it.date.split('T')[0]
  //       return d === pickupDate || d === dropoffDate
  //     })
  //     shipment.metadata.session = valid[0]
  //   }
  //   // this.filterShipments()
  //   return shipments
  // }

  // async loadShipments() {
  //   this.loading = true
  //   const t0 = Date.now()
  //   // const url = Const.APIURI_SHIPMENT_QUEUE('need_routing') + '?limit=1000&filter={"shipmentType":"LTL"}';
  //   // const needRouting = await this.planningService.listShipmentsNeedRouting().toPromise()

  //   const shipments = this.preprocessShipments(needRouting?.shipments ?? []);
  //   const t1 = Date.now()
  //   console.log(`${t1} Loaded ${shipments.length} after ${t1 - t0} ms`)
  //   const shipmentIds = shipments.filter(it => !it.metadata?.issue).map(it => it.id)
  //   // const sessions = await this.planningService.loadShipmentPlanningSessions(shipmentIds).toPromise()
  //   const processed = this.processPlanningSessions(shipments, sessions)
  //   this.shipments = processed;
  //   console.log(shipments.filter(it => it.warpId === 364706))
  //   const t2 = Date.now()
  //   console.log(`${t2} Loaded planning session info after ${t2 - t1} ms`)
  //   this.loading = false
  //   // this.planningService.listShipmentsNeedRouting().subscribe((res) => {
  //   //     this.loading = false
  //   //     this.shipments = res.shipments

  //   //     // non issue
  //   //     const shipmentIds = this._shipments.filter(it => !it.metadata?.issue).map(it => it.id)
  //   //     this.planningService.loadShipmentPlanningSessions(shipmentIds).subscribe((res) => {
  //   //         this.processPlanningSessions(res)
  //   //     })
  //   // })
  // }

  // onRemoveShipment({ id, removed }) {
  //   this._shipments.filter(it => it.id === id).map(r => {
  //     r.excluded = removed
  //   })
  //   // this.processShipments()
  // }
  // onAddedShipmentPlanning({ id, session }) {
  //   this._shipments.filter(it => it.id === id).map(r => {
  //     r.metadata.session = session
  //   })
  //   this.splitShipmentsToTab(this.filtered)
  // }

  // onMouseOverShipment(id) {
  //   const matched = id ? this._shipments.filter(it => it.id === id)[0] : null
  //   // this.map?.highlightShipment(matched)
  // }

  // onClickShipment(id) {
  //   const matched = this._shipments.filter(it => it.id === id)[0]
  //   if (!matched) return
  //   // this.map?.zoomShipment(matched)
  // }

  // onReviewStatusUpdate(event) {
  //   const { id, review } = event
  //   this._shipments.filter(it => it.id === id).map(r => {
  //     r.review = review
  //   })
  //   this.preprocessShipments(this._shipments)
  //   this.processShipments()
  // }

  // onDeliveryInfoUpdate({ id, deliveryInfo }) {
  //   this._shipments.filter(it => it.id === id).map(r => {
  //     r.deliveryInfos = r.deliveryInfos.map(it => it.id === deliveryInfo.id ? deliveryInfo : it)
  //     console.log('Processed update')
  //   })
  //   console.log(`Updated ${id} ${deliveryInfo.type} ${deliveryInfo.id}`)
  //   this.preprocessShipments(this._shipments)
  //   this.processShipments()
  // }

  // onShipmentsUpdated(updated) {
  //   const byId = {}
  //   for (let x of updated) {
  //     byId[x.id] = x
  //   }
  //   const shipments = this._shipments.map(it => {
  //     const u = byId[it.id]
  //     if (!u) return it
  //     return Object.assign(it, u)
  //   })
  //   this._shipments = this.preprocessShipments(shipments)
  //   this.processShipments()
  // }



}
